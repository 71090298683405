import React from "react";
import alarmY from "../imgs/icons/alarmY.svg";
// import Alert from "../imgs/icons/Alert.svg";
import notifiche from "node/notifiche/notifiche";
import i18n from "../i18n";
import { useTranslation } from 'react-i18next';

// Componente che rappresenta un ambiente nella griglia o nella lista delle stanze 
const Ambiente = (props) => {
    const { t } = useTranslation();
    const lang = localStorage.getItem("lang")
    if (lang !== i18n.language) {
        i18n.changeLanguage(lang);
    }
    const accountData = JSON.parse(localStorage.getItem("account"));
    
    function getAmbienteClass(stanza) {
        let sensoreAmbienteCaduta = stanza.sensori.find((item) => item.tipo === "Sensore caduta")
        let sensoreAmbientepresenza = stanza.sensori.find((item) => item.tipo === "Sensore presenza stanza")
        if (sensoreAmbientepresenza === undefined) { sensoreAmbientepresenza = { tipo: "Sensore presenza stanza", stato: "Assente", banner: -1 }; } // se non ha un banner, mostra "offline"
        if (sensoreAmbienteCaduta === undefined) { sensoreAmbienteCaduta = { tipo: "Sensore caduta", stato: "Offline", banner: -1 }; } // se non ha un banner, mostra "offline"

        let fall = false;
        if (sensoreAmbienteCaduta && ((sensoreAmbienteCaduta.stato === "Caduta rilevata!") || (sensoreAmbienteCaduta.banner > 0))) {
            fall = true;
        }
        let offline = false;
        if (stanza.sensori.every((item) => item.stato == "Offline")) {
            offline = true;
        }

        if (offline && fall) {
            return "AmbienteFallAndOffline";
        } else if (offline) {
            return "AmbienteOffline";
        } else if (fall) {
            return "AmbienteFall";
        }

        let busy = "";
        let empty = "";
        switch (stanza.tipo) {
        case "Bagno":
            busy = "AmbienteBagnoBusy";
            empty = "AmbienteBagnoEmpty";
            break;
        case "Varco":
            busy = "AmbienteVarcoBusy";
            empty = "AmbienteVarcoEmpty";
            break;
        case "Cucina":
            busy = "AmbienteCucinaBusy";
            empty = "AmbienteCucinaEmpty";
            break;
        case "Soggiorno":
            busy = "AmbienteSoggiornoBusy";
            empty = "AmbienteSoggiornoEmpty";
            break;
        case "Ascensore":
            busy = "AmbienteAscensoreBusy";
            empty = "AmbienteAscensoreEmpty";
            break;
        default:
            return null;
            break;
        }

        if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Presente") {
            return busy;
        } else if (sensoreAmbientepresenza && sensoreAmbientepresenza.stato === "Assente") {
            return empty;
        }
    }

    async function getNotificheNonConfermate(token, id_residente, id_stanza) { // funzione che prende le notifiche
        //props.loading(true); // attiva il caricamento
        let notificheNonConfermate = []; // array che conterrà le notifiche non confermate
        if (id_residente !== undefined) {
            await notifiche.getNotifiche(token).then((res) => { // prende le notifiche
                for (let notifica of res.data) { // per ogni notifica
                    if (notifica.id_residente === id_residente && notifica.confermata === false) { // se la notifica è del residente e non è confermata
                        notificheNonConfermate.push(notifica); // aggiunge la notifica all'array
                    }
                }
            });
            //props.loading(false); // disattiva il caricamento
        } else {
            await notifiche.getNotifiche(token).then((res) => { // prende le notifiche
                for (let notifica of res.data) { // per ogni notifica
                    if (notifica.id_stanza === id_stanza && notifica.confermata === false && notifica.id_residente === undefined) { // se la notifica è del residente e non è confermata
                        notificheNonConfermate.push(notifica); // aggiunge la notifica all'array
                    }
                }
            });
            //props.loading(false); // disattiva il caricamento
        }
        return notificheNonConfermate; // ritorna l'array

    }

    return (

        <>
            {props.visualizzazione === "griglia" ?
                <div
                    id={props.stanza.sensori.some((item) => (item.tipo == "Sensore presenza stanza") && (item.banner !== 0)) ? "GrigliaAlarm" : "Griglia"}
                    style={{ background: "#FFFFFF", cursor: props.stanza.sensori.some((item) => item.banner !== 0) ? "pointer" : "default" }}
                    onClick={props.stanza.sensori.some((item) => item.banner !== 0) ? async () => props.openPopup("notifiche", await getNotificheNonConfermate(accountData.token, undefined, props.stanza.id_stanza)) : null}
                >

                    {/* {console.log(props.stanza)} */}

                    <table style={{ marginTop: "3%" }}>

                        <tbody>
                            <td style={{ textAlign: "center", color: "black", fontWeight: "800", fontSize: "32px", fontFamily: 'Titillium Web' }} width="100%">

                                {/* {props.stanza.sensori.some((item) => item.banner !== 0) ? (
                                    <div className="lamp-effect" style={{ display: "flex", justifyContent: "right", position: "absolute", bottom: "3%", right: "3%" }}>
                                        <img src={Alert} style={{ width: "35px", height: "35px", alignSelf: "flex-end", cursor: "default" }} />
                                    </div>
                                ) : (
                                    <div style={{ width: "40px" }}></div>
                                )} */}
                                <div style={{ width: "40px" }}></div>

                                {/*{t(props.stanza.tipo)}*/}

                                {t(props.stanza.stanza)}

                            </td>
                        </tbody>
                    </table>
                    <div style={{
                        display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px", width: "100%", height: "60%"
                    }}>
                        <img className={getAmbienteClass(props.stanza)} />
                    </div>
                    <table style={{ marginTop: "3%", height: "20px" }}>
                        <tbody>
                            <td style={{ textAlign: "center", color: "black", fontWeight: "800", fontSize: "15px", fontFamily: 'Titillium Web' }} width="100%">
                            </td>
                        </tbody>
                    </table>
                </div>
                :
                <table
                    id="Lista"
                    onClick={() => props.openPopup("ambiente", props.stanza)}
                    style={{ cursor: "pointer" }}
                >
                    <tbody>
                        <tr>
                            <td width="30%" >
                                {props.stanza.percorso}
                            </td>
                            <td width="%">
                                <div className="alarm" style={{ backgroundColor: "#FEF9E2" }}>
                                    &emsp;{props.stanza.presenza.stato}
                                    <div className="right">
                                        <img src={alarmY} alt="alrm" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </>
    );
}

export default Ambiente;