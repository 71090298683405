import React from "react";
import Alert from "../imgs/icons/Alert.svg";
import departmentDoctor from "../imgs/icons/department-doctor-svgrepo-com 1.svg";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

const Nucleo = (props) => {
  console.log("Nucleo " + props.piano.descrizione)
  console.log(props.piano);
  // console.log(props.piano.sensori);

  const { t } = useTranslation();

  const lang = localStorage.getItem("lang")

  const isAnySensorOffline = props.piano.sensori.some(sensor => sensor.stato === "Offline");

  if (lang !== i18n.language) {
    i18n.changeLanguage(lang);
  }
  if (props.piano.sensori.length > 0) {
    const countAlarms = props.piano.sensori.filter((item) => item.banner !== 0).length;
    const hasAlarms = (countAlarms > 0);
    const countOffline = props.piano.sensori.filter(sensor => sensor.stato === "Offline").length
    // console.log(props.piano.sensori)

    const gradientColor = hasAlarms ? "#FBE6E5" : "#F2F5FF";

    let alarmText = "";
    let alarmDotClass = "";
    if (countAlarms && countOffline) {
      alarmText = t("RilevateAnomalienelPiano".toLowerCase()) + " (" + countAlarms + ")\n" + t("Sensore/i Offline");
      alarmDotClass = "red";
    } else if(countAlarms) {
      alarmDotClass = "red";
      alarmText = t("RilevateAnomalienelPiano".toLowerCase()) + " (" + countAlarms + ")";
    } else if(countOffline) {
      alarmDotClass = "orange";
      alarmText = t("Sensore/i Offline");
    } else {
      alarmDotClass = "green";
      alarmText = t("NessunaAllerta".toLowerCase());
    }

    return (
      <div id={"Griglia"}
        style={{ background: `linear-gradient(180deg, #FFFFFF 45%, ${gradientColor} 60%)` }}>
        <table style={{ marginTop: "20px" }}>
          <tbody>
            <tr>
              <td style={{
                textAlign: "center",
                color: "black",
                fontWeight: "800",
                fontSize: "32px",
                fontFamily: 'Titillium Web'
              }} width="100%">
                {hasAlarms ? (
                  <div className="lamp-effect" style={{
                    display: "flex",
                    justifyContent: "right",
                    position: "absolute",
                    top: "5%",
                    right: "5%"
                  }}>
                    <img src={Alert} style={{ width: "35px", height: "35px", alignSelf: "flex-end", cursor: "default" }} />
                  </div>
                ) : (
                  <div style={{ width: "40px" }}></div>
                )}
                {props.piano.descrizione}
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ fontSize: "80%", color: "#6A6E83", width: "100%" }}></td>
            </tr>
            <tr>
              {/* Corrected the colSpan to 2 since there are only two columns in this row */}
              <td colSpan={2}>
                <br /><br />
                <div className="alarmG" style={{ fontSize: "80%", textAlign: "center" }}>
                  &nbsp; {alarmText}
                  <div className="right">
                    <div className={`dot ${alarmDotClass}`} style={{ right: "0" }}>&nbsp;</div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    );
  }
}

export default Nucleo;
