// import React, { useState } from "react";
//
// import close from "../imgs/icons/close.svg";
// import save from '../imgs/icons/save.svg';
// import addW from '../imgs/icons/addW.svg';
// import carica from '../imgs/icons/carica.svg';
// import delet from '../imgs/icons/delete.svg';
// import modifica from '../imgs/icons/modifica.svg';
// import confirmW from '../imgs/icons/confirmW.svg';
// import lang from "../lang.json";
//
// const Blebeacon = () => {
//
//     const [macAddress, setMacAddress] = useState([]);
//
//     const addMacAddress = () => {
//         setMacAddress([...macAddress, '']);
//     };
//
//     const saveMacAddress = async () => {
//
//         console.log('test');
//         try {
//             const response = await fetch('http://localhost:3000/save-mac-address', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ macAddress }),
//             });
//             const data = await response.json();
//             console.log(data);
//         } catch (error) {
//             console.error('Error saving MAC address:', error);
//         }
//     };
//
//     return (
//         <div>
//             <table id="TitoliLista">
//                 <tbody>
//                 <tr>
//                     <td width="15%" style={{ border: 0 }}>Indirizzo MAC</td>
//                     <td width="62.5%" style={{ border: 0 }}></td>
//                 </tr>
//                 </tbody>
//             </table>
//             <div style={{ overflow: "scroll", minHeight: "20em", maxHeight: "25em" }}>
//                 <h1>tetstst</h1>
//                 {macAddress.map((mac, index) => (
//                     <input
//                         key={index}
//                         type="text"
//                         value={mac}
//                         onChange={(e) => {
//                             const newMacAddress = [...macAddress];
//                             newMacAddress[index] = e.target.value;
//                             setMacAddress(newMacAddress);
//                         }}
//                         placeholder="Enter MAC Address"
//                     />
//                 ))}
//             </div>
//             <div className="navigators">
//                 {mod === 0 ? (
//                     <div className="selettore selettoreFlex selettoreViolet" onClick={() => setMod(1)}>
//                         <p>{langSet.modifica}</p>
//                         <img src={modifica} alt='modifica' style={{ width: "1em" }} />
//                     </div>
//                 ) : (
//                     <>
//                         <div className="selettore selettoreFlex selettorePurple normPosition" onClick={addMacAddress}>
//                             <p>aggiungi</p>
//                             <img src={addW} alt="add" />
//                         </div>
//
//                         <div className={defContinua} onClick={saveMacAddress}>
//                             <p>{langSet.salva}</p>
//                             <img src={confirmW} alt='arrow' />
//                         </div>
//
//                         <div className="selettore selettoreFlex selettoreViolet">
//                             <p>{langSet.annulla}</p>
//                             <img src={close} alt="close" />
//                         </div>
//                     </>
//                 )}
//             </div>
//         </div>
//     );
// }
//
// export default Blebeacon;


import React, { useState } from "react";
import close from "../imgs/icons/close.svg";
import save from '../imgs/icons/save.svg';
import addW from '../imgs/icons/addW.svg';
import carica from '../imgs/icons/carica.svg';
import delet from '../imgs/icons/delete.svg';
import modifica from '../imgs/icons/modifica.svg';
import confirmW from '../imgs/icons/confirmW.svg';
import lang from "../lang.json";

const Blebeacon = () => {
    const [macAddress, setMacAddress] = useState([]);
    const [mod, setMod] = useState(0); // State to manage view mode

    const addMacAddress = () => {
        setMacAddress([...macAddress, '']);
    };

    const saveMacAddress = async () => {
        console.log('test');
        try {
            const response = await fetch('http://localhost:3000/save-mac-address', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ macAddress }),
            });
            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error('Error saving MAC address:', error);
        }
    };

    return (
        <div>
            <table id="TitoliLista">
                <tbody>
                <tr>
                    <td width="15%" style={{ border: 0 }}>Indirizzo MAC</td>
                    <td width="62.5%" style={{ border: 0 }}></td>
                </tr>
                </tbody>
            </table>
            <div style={{ overflow: "scroll", minHeight: "20em", maxHeight: "25em" }}>
                <h1>tetstst</h1>
                {macAddress.map((mac, index) => (
                    <input
                        key={index}
                        type="text"
                        value={mac}
                        onChange={(e) => {
                            const newMacAddress = [...macAddress];
                            newMacAddress[index] = e.target.value;
                            setMacAddress(newMacAddress);
                        }}
                        placeholder="Enter MAC Address"
                    />
                ))}
            </div>
            <div className="navigators">
                {mod === 0 ? (
                    <div className="selettore selettoreFlex selettoreViolet" onClick={() => setMod(1)}>
                        <p>{lang.modifica}</p>
                        <img src={modifica} alt='modifica' style={{ width: "1em" }} />
                    </div>
                ) : (
                    <>
                        <div className="selettore selettoreFlex selettorePurple normPosition" onClick={addMacAddress}>
                            <p>aggiungi</p>
                            <img src={addW} className="icon-popup2" alt="add" />
                        </div>

                        <div className="selettore selettoreFlex selettorePurple normPosition" onClick={saveMacAddress}>
                            <p>{lang.salva}</p>
                            <img src={confirmW} alt='arrow' />
                        </div>

                        <div className="selettore selettoreFlex selettoreViolet">
                            <p>{lang.annulla}</p>
                            <img src={close} alt="close" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );}
export default Blebeacon;
