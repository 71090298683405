import React, { useEffect, useState } from "react";
import Alert from "../imgs/icons/Alert.svg";
import busybed from "../imgs/icons/Busybed.svg";
import falldetected from "../imgs/icons/falldetected.svg";
import emptyroom from "../imgs/icons/emmptyroom.svg";
import offlinebedroom from "../imgs/icons/offline-icon.svg";
import notifiche from "node/notifiche/notifiche";


const Stanza = (props) => {
  const accountData = JSON.parse(localStorage.getItem("account"));

  // questa funzione forse non è più usata; c'è un altro punto del codice che la chiama, forse per residenti C e D?
  const getimgState = (residente) => {
    let sensoreLetto = residente.sensori.find((item) => item.tipo === "Sensore letto") || { tipo: "Sensore letto", stato: "Assente", banner: -1 };
    let sensoreCaduta = residente.sensori.find((item) => item.tipo === "Sensore caduta") || { tipo: "Sensore caduta", stato: "Offline", banner: -1 };
    let sensorepresenzastanza = residente.sensori.find((item) => item.tipo === "Sensore presenza stanza") || { tipo: "Sensore presenza stanza", stato: "Assente", banner: -1 };
    if (residente.sensori.every((item) => item.stato == "Offline")) return offlinebedroom;
    if (sensoreCaduta.stato === "Caduta rilevata!") return falldetected;
    if (sensoreLetto.stato === "Presente") return busybed;
    if (sensoreLetto.stato === "Assente" && sensorepresenzastanza.stato === "Presente") return emptyroom;
    if (sensoreLetto.stato === "Assente") return emptyroom;
    if (sensorepresenzastanza.stato === "Presente") return busybed;

    return offlinebedroom;
  };

  const getClassResidente = (_residente, _bannerPresenzaStanza) => {
    let sensoreLetto = _residente.sensori.find((item) => item.tipo === "Sensore letto") ||
      { tipo: "Sensore letto", stato: "Assente", banner: -1 };
    let sensoreCaduta = _residente.sensori.find((item) => item.tipo === "Sensore caduta") ||
      { tipo: "Sensore caduta", stato: "Offline", banner: -1 };
    let sensorepresenzastanza = _residente.sensori.find((item) => item.tipo === "Sensore presenza stanza") ||
      { tipo: "Sensore presenza stanza", stato: "Assente", banner: -1 };

    let offline = false;
    if (_residente.sensori.some((item) => (item.stato == "Offline")) ||   // better 'some' then 'every' since Sensore Letto does not get Offline (?)
        _residente.sensori.length == 0) {
      offline = true;
    }
    let fall = false;
    if ((sensoreCaduta.stato === "Caduta rilevata!") || (sensoreCaduta.banner > 0)) {
      fall = true;
    }
    const allertaLetto = (sensoreLetto.banner > 0)
    const presenteStanza = (sensorepresenzastanza.stato === "Presente");
    const presenteLetto = (sensoreLetto.stato === "Presente");
    // il contorno presenza stanza viene inserito da un'altra parte; anche l'allarme stanza
    // console.log("Dati sesnore (" + _residente.id_residente + ") offline: " + offline + " fall: " + fall + " allerta letto: " + allertaLetto + " presenza letto: " + presenteLetto)

    if (!offline && !fall && !allertaLetto) {
      if (presenteLetto) return _bannerPresenzaStanza ? "ResidentBedBusy_Presence" : "ResidentBedBusy";
      else return _bannerPresenzaStanza ? "ResidentBedEmpty_Presence" : "ResidentBedEmpty";
    }

    if (!offline && !fall && allertaLetto) {
      if (presenteLetto) return "ResidentBedBusyAlarm";
      else return "ResidentBedEmptyAlarm";
    }

    if (!offline && fall && !allertaLetto) {
      return "ResidentBedFall";
    }

    if (!offline && fall && allertaLetto) {
      if (presenteLetto) return "ResidentBedBusyAlarmAndFall";
      else return "ResidentBedEmptyAlarmAndFall";
    }

    if (offline && !fall && !allertaLetto) {
      return _bannerPresenzaStanza ? "ResidentBedOffline_Presence" : "ResidentBedOffline";
    }

    if (offline && !fall && allertaLetto) {
      return "ResidentBedAlarmAndOffline";
    }

    if (offline && fall && !allertaLetto) {
      return "ResidentBedFallAndOffline";
    }

    if (offline && fall && allertaLetto) {
      return "ResidentBedAlarmAndFallAndOffline";
    }

    return _bannerPresenzaStanza ? "ResidentBedOffline_Presence" : "ResidentBedOffline";
  }

  const getClassAmbiente = (_sensoriAmbiente, _bannerPresenzaStanza) => {
    let sensoreAmbienteCaduta = _sensoriAmbiente.find((item) => item.tipo === "Sensore caduta") || { tipo: "Sensore caduta", stato: "Offline", banner: -1 };
    let sensoreAmbientepresenza = _sensoriAmbiente.find((item) => item.tipo === "Sensore presenza stanza") || { tipo: "Sensore presenza stanza", stato: "Assente", banner: -1 };
    
    let offline = false;
    let fall = false;
    if (_sensoriAmbiente.every((item) => item.stato == "Offline")) offline = true; // 'every' is ok since Sensore Letto cannot be used here (?)
    if ((sensoreAmbienteCaduta.stato === "Caduta rilevata!") || (sensoreAmbienteCaduta.banner > 0)) fall = true;
    
    if (offline && fall) {
      return "BathroomFallAndOffline";
    } else if(offline) {
      return _bannerPresenzaStanza ? "BathroomOffline_Presence" : "BathroomOffline";
    } else if(fall) {
      return "BathroomFall";
    }
    if (sensoreAmbientepresenza.stato === "Presente") {
      return _bannerPresenzaStanza ? "BathroomBusy_Presence" : "BathroomBusy";
    } if (sensoreAmbientepresenza.stato === "Assente") {
      return _bannerPresenzaStanza ? "BathroomEmpty_Presence" : "BathroomEmpty";
    }

    return _bannerPresenzaStanza ? "BathroomOffline_Presence" : "BathroomOffline";
  };

  const getNotificheNonConfermate = async (token, id_residente, id_stanza) => {
    props.loading(true);

    let notificheNonConfermate = [];

    if (id_residente !== undefined) {
      await notifiche.getNotifiche(token).then((res) => {
        for (let notifica of res.data) {
          if (notifica.id_residente === id_residente && !notifica.confermata) {
            notificheNonConfermate.push(notifica);
          }
        }
      });
      props.loading(false);
    } else {
      await notifiche.getNotifiche(token).then((res) => {
        for (let notifica of res.data) {
          if (notifica.id_stanza === id_stanza && !notifica.confermata && notifica.id_residente === undefined) {
            notificheNonConfermate.push(notifica);
          }
        }
      });
      props.loading(false);
    }
    return notificheNonConfermate;
  };

  function checkAlarm(stanza) {
    // console.log("BANNER!")
    // console.log(stanza)
    let count = 0;
    stanza.data.map((resident) => {
      resident.sensori.map((s) => {
        count = count + s.banner;
        if (count > 0) return true;
      }
      );
      if (typeof resident.sensori_ambiente !== 'undefined') {
        resident.sensori_ambiente.map((s) => {
          if (typeof s !== 'undefined') {
            count = count + s.banner;
            if (count > 0) return true;
          }
        }
        );
      }
    });
    // console.log("count: " + count);
    return (count > 0); //should return false if it reaches this point
  };

  return (



    <div key={"top" + props.stanza.id_stanza} id={"Griglia"}>

      <table style={{ marginTop: "3%" }}>
        <tbody >
          <tr>
            <td style={{ textAlign: "center", color: "black", fontWeight: "800", fontSize: "32px", fontFamily: 'Titillium Web' }}>
              {/* {checkAlarm(props.stanza) ? (
                <div className="lamp-effect" style={{ display: "flex", justifyContent: "right", position: "absolute", bottom: "2%", right: "5%" }}>
                  <img src={Alert} style={{ width: "35px", height: "35px", alignSelf: "flex-end", cursor: "default" }} />
                </div>
              ) : (
                <div style={{ width: "40px" }}></div>
              )} */}
              <div style={{ width: "40px" }}></div>
              {props.numeroStanza}
            </td>
          </tr>
        </tbody>
      </table>

      <div key={"content" + props.stanza.id_stanza + props.stanza.tipo} style={{ display: "flex", width: props.letti === 1 ? "76px" : "152px", borderRadius: "5px 20px 5px 5px", border: ".5px solid #CDDEFF", alignItems: "center", color: "black", fontWeight: "600", fontSize: "20px", height: "40px", justifyContent: "", marginTop: "20px" }}>
        {/* {console.log(props.stanza.data)} */}
        {props.stanza.data.sort((a, b) =>
          // ordina in base al ultimo carattere dell'id_residente
          a.id_residente.charAt(a.id_residente.length - 1) > b.id_residente.charAt(b.id_residente.length - 1) ? 1 : -1

        ).map((residente, index) => {

          return (

            <React.Fragment key={residente.id_residente + "container"}>
              {index <= 1 ? (
                <>
                  <div key={residente.id_residente + "bedName"} style={{ padding: "7px" }}>
                    {residente.id_residente.charAt(residente.id_residente.length - 1)}
                  </div>
                  <div key={residente.id_residente + "imgbox"} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}
                    onClick={residente.sensori.some((item) => item.banner !== 0) ? async () => props.openPopup("notifiche", await getNotificheNonConfermate(accountData.token, residente.id_residente)) : null}
                  >

                    {index === 0 || 2 ? (
                      <div key={residente.id_residente + "img"}
                        className={residente.sensori.find((item) => item.tipo === "Sensore presenza stanza") &&
                                   residente.sensori.find((item) => item.tipo === "Sensore presenza stanza").stato === "Presente" ||
                                   residente.sensori.find((item) => item.tipo === "Sensore letto") &&
                                   residente.sensori.find((item) => item.tipo === "Sensore letto").stato === "Presente"
                                   ? residente.sensori.some((item) => (item.tipo == "Sensore presenza stanza") && (item.banner > 0))
                                     ? "ResidentPresentAlarm"
                                     : "ResidentPresent"
                                   : residente.sensori.some((item) => (item.tipo == "Sensore presenza stanza") && (item.banner > 0))
                                     ? "ResidentAlarm"
                                     : "Resident"
                                  }
                      >
                        <img className={getClassResidente(residente,
                                                          (
                                                            residente.sensori.find((item) => item.tipo === "Sensore presenza stanza") &&
                                                            residente.sensori.find((item) => item.tipo === "Sensore presenza stanza").stato === "Presente" ||
                                                            residente.sensori.find((item) => item.tipo === "Sensore letto") &&
                                                            residente.sensori.find((item) => item.tipo === "Sensore letto").stato === "Presente" &&
                                                            residente.sensori.some((item) => (item.tipo == "Sensore presenza stanza") && (item.banner > 0))
                                                          )
                                                         )
                                        }
                        />
                      </div>
                    ) : (
                      <div key={residente.id_residente + "null"}></div>
                    )}

                  </div>
                </>
              ) : null
              }
            </React.Fragment>
          );
        })}
      </div>

      {props.letti > 2 ? (
        <div style={{ display: "flex", width: props.letti === 1 ? "76px" : "152px", borderRadius: "5px 20px 5px 5px", border: ".5px solid #CDDEFF", alignItems: "center", color: "black", fontWeight: "600", fontSize: "1.15em", marginTop: "30px", height: "40px", justifyContent: "center" }}>
          {props.stanza.data.map((residente, index) => {

            return (
              <React.Fragment key={residente.id_residente}>
                {index > 1 ? (
                  <>
                    <div key={residente.id_residente + "bedName"} style={{ padding: "10px" }}>
                      {index}
                    </div>
                    <div key={index.id_residente + "imgbox"} style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                      {index === 0 || 2 ? (
                        <div key={residente.id_residente} style={{
                          width: "20px",
                          borderRadius: "5px 20px 5px 20px",
                          padding: "15px",
                          borderLeft: ".5px solid #CDDEFF",
                          borderRight: index === 1 || index === 3 || props.letti === 1 && index === 0 ? "none" : ".5px solid #CDDEFF",
                          borderTop: ".5px solid #CDDEFF",
                          borderBottom: ".5px solid #CDDEFF",
                          color: "black",
                          fontWeight: "600",
                          fontSize: "1.15em",
                          justifyContent: "center",
                          display: "flex",
                          height: "10px",
                          alignItems: "center",
                        }}>
                          {/*{ props.letti } { index }*/}
                          <img src={getimgState(residente)} style={{ width: "40px", height: "40px" }} />
                        </div>
                      ) : (
                        <div key={residente.id_residente + "null"}></div>
                      )}
                    </div>
                  </>
                ) : null
                }
              </React.Fragment>
            );
          })}
        </div>
      ) : null}

      {props.stanza.data[0].sensori_ambiente[0] !== undefined ? (
        <>
          <div style={{ display: "flex", width: "76px", borderRadius: "5px 20px 5px 5px", border: ".5px solid #CDDEFF", alignItems: "center", color: "black", fontWeight: "600", fontSize: "1.15em", marginTop: "30px", height: "40px", justifyContent: "center" }}
            onClick={props.stanza.data[0].sensori_ambiente.some((item) => item.banner !== 0) ? async () => props.openPopup("notifiche", await getNotificheNonConfermate(accountData.token, undefined, props.stanza.data[0].id_stanza)) : null}>

            <div style={{ padding: "4px", fontSize: "13px", marginLeft: "11px"}}>WC</div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
              <div className={
                props.stanza.data[0].sensori_ambiente.some((item) => ((item.tipo === "Sensore presenza stanza") && 
                                                                      (item.stato === "Presente")))
                ? props.stanza.data[0].sensori_ambiente.some((item) => (item.tipo === "Sensore presenza stanza") && (item.banner > 0))
                  ? "BathroomPresentAlarm"
                  : "BathroomPresent"
                : props.stanza.data[0].sensori_ambiente.some((item) => (item.tipo === "Sensore presenza stanza") && (item.banner > 0))
                  ? "BathroomAlarm"
                  : "Bathroom"
              }>

                <img className={getClassAmbiente(props.stanza.data[0].sensori_ambiente,
                                                 props.stanza.data[0].sensori_ambiente.some((item) => ((item.tipo === "Sensore presenza stanza") && (item.banner > 0))))
                } />

              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Stanza;
