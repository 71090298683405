import React, { useState, useEffect } from "react";

import close from "../imgs/icons/close.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import strutturaNode from 'node/struttura/struttura.js';
import stanzeNode from 'node/stanze/stanze';
import Switch from "react-switch";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";
import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_stanza;

const NuovaStanza = (props) => {
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [mod, setMod] = useState(0);
    const [status, setStatus] = useState(0);
    const [id, setId] = useState();
    const [tipo, setTipo] = useState();
    const [descrizione, setDescrizione] = useState();
    const [idThinkin, setIdThinkin] = useState();
    const [numLetti, setNumLetti] = useState(tipo === "Resident Room" ? "" : 0);
    const [struttura, setStruttura] = useState([]);
    const [indexPiano, setIndexPiano] = useState(0);
    const [piano, setPiano] = useState();
    const [indexAla, setIndexAla] = useState(0);
    const [ala, setAla] = useState();
    const [corridoio, setCorridoio] = useState();
    const [multipleroom, setMultipleRoom] = useState(false);
    const [fromRoom, setFromRoom] = useState();
    const [toRoom, setToRoom] = useState();
    //const tipiStanze = ["Resident Room", "Ascensore", "Scala", "Varco", "Bagno", "Ingresso Principale", "Uscita Emergenza", "Uscita Animazione", "Uscita Principale", "Uscita Centro Assistenza Diurno", "Soggiorno", "Cucina" ];

    const tipiStanze = ["Resident Room", "Ascensore", "Varco", "Bagno", "Ingresso Principale", "Uscita Principale", "Soggiorno", "Cucina" ];

    const defSelettore = "selettore selettoreFlex selettoreMiddle selettoreBorder";
    const selSelettore = "selettore selettoreFlex selettoreMiddle selettorePurple selettoreShadow";
    const defContinua = "selettore selettoreFlex selettoreBlue";
    const posizioni = ["DX", "SX", "Centrale", "Corridoio", "Ingresso", "Uscita"];

    // funzione che mi ritorna il nome,la stanza, corridoio in cui sono
    const { t } = useTranslation();
    const lang = localStorage.getItem("lang")
    if (lang !== i18n.language) {
    i18n.changeLanguage(lang);
    } 
    
    async function getStructure() {
        props.loading(true);
        console.log(props);
        await strutturaNode.getStrutturaNuovaStanza(accountData.token).then((res) => {
            setStruttura(res.data);
            console.log(res.data);
            if (props.percorso) {
                setIndexPiano(res.data.findIndex((x) => x.id === props.percorso.piano));
                setPiano(props.percorso.piano);
                setIndexAla(res.data[indexPiano].id_ale.findIndex((x) => x.id === props.percorso.ala));
                setAla(props.percorso.ala);
                setCorridoio(props.percorso.corridoio);
            }
        }).finally(() => {
            props.loading(false);
        });
    }

    function setAlert() {
        if (tipo !== "Resident Room") {
            return true;
        } else if (tipo === "Resident Room" && !isNaN(numLetti)) {
            return true;
        }

    }

    // nuova stanza
    async function aggiungiStanza() {
        props.loading(true);
        if (multipleroom) {
            for (let i = fromRoom; i <= toRoom; i++) {
                let type;
                if (tipo === "Resident Room") {
                    type = "Stanza";
                } else if (tipo === "Ascensore") {
                    type = "Ascensore";
                }
                else if (tipo === "Scala") {
                    type = "Scala";
                }
                else if (tipo === "Varco") {
                    type = "Varco";
                }
                else if (tipo === "Bagno") {
                    type = "Bagno";
                }

                await stanzeNode.addStanza(accountData.token, i.toString(), type + " " + i.toString(), tipo, { piano: piano, ala: ala, corridoio: corridoio }, numLetti, 0).then(() => {
                    props.getStr();
                    props.closePopup();
                }).finally(() => {
                    
                });
                console.log(i);
            }
            props.loading(false);
        } else {
        await stanzeNode.addStanza(accountData.token, id, descrizione, tipo, { piano: piano, ala: ala, corridoio: corridoio }, numLetti, idThinkin).then(() => {
            props.getStr();
            props.closePopup();
        }).finally(() => {
            props.loading(false);
        });
    }
    }
    

    useEffect(() => {
        console.log('entrato');
        setId("");
        setTipo("");
        setDescrizione("");
        setIdThinkin("");
        //setNumLetti("");
        setPiano(undefined);
        setAla(undefined);
        setCorridoio(undefined);
        getStructure();
        if (tipo !== "Resident Room") {
            setNumLetti(0);
        } else {
            setNumLetti("");
        }
        //console.log(props);
    }, [props]);

    return (
        <>
            <div id="Header" className="headerPopup" style={{ justifyContent: "start", overflowY: "auto" }}>
                <div className={selSelettore}>
                    <p>{langSet.dettagli}</p>
                </div>
                <div className={status === 1 ? selSelettore : `disabled ${defSelettore}`}>
                    <p>{langSet.sensoriLetto}</p>
                </div>
                <div className={status === 2 ? selSelettore : `disabled ${defSelettore}`}>
                    <p>{langSet.sensoriCamera}</p>
                </div>
                <div className={status === 3 ? selSelettore : `disabled ${defSelettore}`}>
                    <p>{langSet.sensoriBagno}</p>
                </div>
            </div>
            <div id="PopupBody">
                <form id="form" onSubmit={aggiungiStanza}>
                    <table className="scheda" style={{ display: status === 0 ? "" : "none" }}>
                        <tbody>
                            
                            <tr>
                                {multipleroom ?
                                <><td width="15%">
                                        <label>{"Da StanzaN"}</label>
                                        <input
                                            type="text"
                                            value={fromRoom}
                                            placeholder={"Inserisci da quale stanza iniziare"}
                                            onChange={(e) => setFromRoom(e.target.value)}
                                            required />
                                    </td><td width="15%">
                                            <label>{"a StanzaN"}</label>
                                            <input
                                                type="text"
                                                value={toRoom}
                                                placeholder={"Inserisci da quale stanza iniziare"}
                                                onChange={(e) => setToRoom(e.target.value)}
                                                required />
                                        </td></>
                                :
                                <td width="15%">

                                    <label>{langSet.stanzaN}°</label>
                                    <input
                                        type="text"
                                        value={id}
                                        placeholder={langSet.inserisciNumeroStanza}
                                        onChange={(e) => setId(e.target.value)}
                                        required
                                    />
                                </td>
                                }
                                <td width="15%">
                                    <label>{langSet.tipo}</label>
                                    <select defaultValue="0" onChange={(e) => setTipo(e.target.value)} required>
                                        <option value="0" disabled>- - -</option>
                                        {tipiStanze.map((currTipo, index) => <option key={index} value={currTipo}>{currTipo}</option>)}
                                    </select>
                                </td>
                                {tipo === "Resident Room" ?
                                <td width="15%" style={multipleroom ? { display: "none" } : {}}>
                                    <label>{langSet.descrizione}</label>
                                    <input
                                        type="text"
                                        value={descrizione}
                                        placeholder={langSet.inserisciDescrizione}
                                        onChange={(e) => setDescrizione(e.target.value)}
                                        required
                                    />
                                </td>
                                :
                                <td width="15%" >
                                    <label>{"Posizione"}</label>
                                    <select defaultValue="0" onChange={(e) => setDescrizione(e.target.value)} required>
                                        <option value="0" disabled>- - -</option>
                                        {posizioni.map((currTipo, index) => <option key={index} value={currTipo}>{t(currTipo.toLowerCase())}</option>)}
                                    </select>
                                </td>
                                }
                                <td width="15%">
                                <label>{"Abilita creazione Multipla"}</label>
                                    
                                 <Switch
                                    checked={multipleroom}
                                    onChange={() => setMultipleRoom(!multipleroom)}
                                    onColor="#168AF5"
                                    offColor="#E5E5E5"
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={20}
                                    width={40}
                                    handleDiameter={15}
                                    className="react-switch"
                                    id="material-switch"
                                    />
                                </td>
                                <td width="40%" align="right" valign="bottom" rowSpan={2}>
                                    {/* <input type="submit" value="Salva e continua" className={defContinua} src={confirmW} alt='confirm' /> */}
                                    
                                </td>
                            </tr>
                            <tr>
                                
                                <td width="15%" style={tipo === "Resident Room" ? {} : { display: "none" }}>
                                    <label>{langSet.nLetti}</label>
                                    <input
                                        type="number"
                                        value={numLetti}
                                        placeholder={langSet.inserisciNumeroLetti}
                                        onChange={(e) => setNumLetti(e.target.value)}
                                        min="1"
                                        max="8"
                                        required
                                    />
                                </td>
                                <td width="15%" rowSpan={2}>
                                    <div>
                                        <label>{langSet.piano}</label>
                                        <select defaultValue="0" value={piano ?? '0'} onChange={(e) => { setIndexPiano(struttura.findIndex((x) => x.id === e.target.value)); setPiano(e.target.value); }} required>
                                            <option value="0" disabled>- - -</option>
                                            {struttura.map((currPiano, index) => (
                                                <option key={index} value={currPiano.id}>{currPiano.id}</option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                                <td width="15%" rowSpan={2}>
                                    <div>
                                        <label>{langSet.ala}</label>
                                        <select defaultValue="0" value={ala ?? '0'} onChange={(e) => { setIndexAla(struttura[indexPiano].id_ale.findIndex((x) => x.id === e.target.value)); setAla(e.target.value); }} required>
                                            <option value="0" disabled>- - -</option>
                                            {piano ? struttura[indexPiano].id_ale.map((currAla, index) => (
                                                <option key={index} value={currAla.id}>{currAla.id}</option>
                                            )) :
                                                <>
                                                </>
                                            }
                                        </select>
                                    </div>
                                </td>
                                <td width="15%">
                                    <div>
                                        <label>{langSet.corridoio}</label>
                                        <select defaultValue="0" value={corridoio ?? '0'} onChange={(e) => setCorridoio(e.target.value)} required>
                                            <option value="0" disabled>- - -</option>
                                            {ala ? struttura[indexPiano].id_ale[indexAla].id_corridoi.map((currCorridoio, index) => (
                                                <>
                                                    <option key={index} value={currCorridoio}>{currCorridoio}</option>{console.log(struttura[indexPiano].id_ale[indexAla])}</>
                                            )) :
                                                <>
                                                </>
                                            }
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            
                        </tbody>
                        <div style={{marginTop:"5%", display:"flex", flexDirection:"row", justifyContent:"space-between"
                         }} className={tipo && setAlert() && piano && ala && corridoio ? defContinua : "disabled " + defContinua}
                                        onClick={tipo && setAlert() && piano && ala && corridoio ? () => aggiungiStanza() : () => { }}
                                    >
                                        <p>{langSet.conferma}</p>
                                        <img src={confirmW} alt="confirm" />
                                    </div>
                                    <div className="selettore selettoreFlex selettoreViolet" onClick={() => props.closePopup()}>
                                        <p>{langSet.annulla}</p>
                                        <img src={close} alt="close" />
                                    </div>
                        
                    </table>
                </form>
            </div>
        </>
    );
}

export default NuovaStanza;