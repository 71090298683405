import React, { useState, useRef, useEffect } from "react";

import close from "../imgs/icons/close.svg";
import confirmW from '../imgs/icons/confirmW.svg';
import arrowPurple from '../imgs/icons/arrowPurple.svg';
import positionPurple from '../imgs/icons/positionPurple.svg';
import alarmR from "../imgs/icons/alarmR.svg";

import notificheNode from "node/notifiche/notifiche.js";
import sensoreNode from "node/sensori/sensori.js";
import residentiNode from "node/residenti/residenti.js";
import stanzeNode from "node/stanze/stanze.js";

import lang from "../lang.json";
const langSet = lang[localStorage.getItem("lang")].scheda_notifica;

const SchedaNotifica = (props) => {
    const defSelettore = "selettore selettoreBorder selettoreFlex mini-selettore";
    const accountData = JSON.parse(localStorage.getItem("account"));
    const [nota, setNota] = useState('');
    const scrollRef = useRef(null);

    // const [isChecked, setIsChecked] = React.useState([]);
    //
    // const [notificheFeedback , setNotificheFeedback] = React.useState(Array());

    function resetData() {
        setNota('');
    }

    function scroll(dir) { // dir = "left" || "right"
        const item = scrollRef.current;
        const distance = 150;
        const duration = 350;
        const increment = distance / duration * 10;
        let counter = 0;
        let intervalId;
        if (dir === "left") {
            intervalId = setInterval(() => {
                item.scrollBy(-increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        } else {
            intervalId = setInterval(() => {
                item.scrollBy(increment, 0);
                counter += 10;
                if (counter >= duration) clearInterval(intervalId);
            }, 10);
        }
    }


    useEffect(() => {   
        const item = scrollRef.current;
        item.addEventListener("wheel", function (e) {
            if (e.deltaY > 0) item.scrollLeft += 20;
            else item.scrollLeft -= 20;
        });
        if (item.scrollWidth > item.clientWidth) {
            document.getElementsByClassName("popupNavigator")[0].style.display = "flex";
        } else {
            document.getElementsByClassName("popupNavigator")[0].style.display = "none";
        }
    }, []);

    async function confermaNotifica() { // Conferma la notifica e aggiunge una nota se presente nel campo di testo
        props.loading(true);
        if (!props.notifica.confermata) {
            let responseConferma = await notificheNode.patchStatoNotifica(accountData.token, props.notifica.id);
            if (!responseConferma.result) props.SchedaInScheda('alarm', responseConferma.error.toString(), 'ritorna_notifica', props.notifica)
        }

        if (nota && nota !== '') {
            let responseNota = await notificheNode.putNotaNotifica(accountData.token, props.notifica.id, nota);
            if (!responseNota.result) props.SchedaInScheda('alarm', responseNota.error.toString(), 'ritorna_notifica', props.notifica);
        }
        await props.getNot("creato", "asc");
        props.loading(false);
        props.closePopup();

        // window.location.reload(false);
    }
    
    async function getStanza(id) { // Ottiene la stanza e la passa alla scheda stanza
        await stanzeNode.getStanza(accountData.token, id).then((response) => {
            if (!response.result) throw response.error;
            props.SchedaInScheda('scheda_stanza', response.data, 'ritorna_notifica', props.notifica);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_notifica', props.notifica));
    }

    async function getResidente(id) {   // Ottiene il residente e lo passa alla scheda residente
        props.loading(true);
        await residentiNode.getResidente(accountData.token, id).then((response) => {
            if (!response.result) throw response.error;
            props.SchedaInScheda('scheda_residente', response.data, 'ritorna_notifica', props.notifica);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_notifica', props.notifica)).finally(() => {
            props.loading(false);
        });
    }

    async function getSensore(id) { // Ottiene il sensore e lo passa alla scheda sensore  
        props.loading(true);
        await sensoreNode.getSensore(accountData.token, id).then((response) => {
            if(!response.result) throw response.error;
            props.SchedaInScheda('scheda_sensore', response.data, 'ritorna_notifica', props.notifica);
        }).catch((e) => props.SchedaInScheda('alarm', e.toString(), 'ritorna_notifica', props.notifica)).finally(() => {
            props.loading(false);
        });
    }

    // console.log(notificheNode);
    console.log(props.notifica);

    return (
        <>
            <div id="PopupBody">
                <div className="popupCardContainer" ref={scrollRef}>

                    <div className="popupCard">
                        <table className="scheda" style={{ width: "40em" }}>
                            <tbody>
                            <tr>
                                <td colSpan={12} style={{padding: ".5em"}}>
                                    {/*<label>{langSet.tipo}</label>*/}
                                    {/*<p>{props.notifica.tipo}</p>*/}
                                    <label>Tipologia</label>
                                    {/*<p>{props.notifica.messaggio == "Il paziente è caduto" ? 'Possibile caduta' : props.notifica.messaggio}</p>*/}

                                    <p>{ props.notifica.messaggio == 'Il paziente è caduto' ? 'Possibile caduta' : props.notifica.messaggio == 'Il letto è vuoto' ? 'Uscita letto' : props.notifica.messaggio }</p>

                                </td>
                            </tr>

                            {/*<tr>*/}
                            {/*    <td colSpan={3} style={{padding: ".5em", width: "17.5em" }}>*/}
                            {/*        <label>{langSet.stanza}</label>*/}
                            {/*        <p>{props.notifica.id_stanza}</p>*/}
                            {/*       */}
                            {/*    </td>*/}
                            {/*    <td colSpan={3} style={{ padding: ".5em", width: "17.5em" }}>*/}
                            {/*        <label>{langSet.residente}</label> */}
                            {/*        <p>{props.notifica.residente ? props.notifica.residente : " - "}</p>*/}
                            {/*       */}
                            {/*    </td>*/}
                            {/*</tr>*/}

                            <tr>
                                <td colSpan={12} style={{padding: ".5em", width: "17.5em"}}>
                                    <label>{langSet.stanza} / {langSet.residente}</label>
                                    <p>{props.notifica.id_stanza} - {props.notifica.residente ? props.notifica.residente : " - "}</p>
                                </td>
                            </tr>

                            <tr>
                                {/*<td colSpan={2} style={{padding: ".5em"}}>*/}
                                {/*    <label>{langSet.creatoIl}</label>*/}
                                {/*    <p>{props.notifica.creato}</p>*/}
                                {/*</td>*/}

                                {/*<td colSpan={2} style={{*/}
                                {/*    padding: ".5em",*/}
                                {/*    display: props.notifica.confermata ? "" : "none"*/}
                                {/*}}> /!* Se la notifica è confermata mostra la data di risoluzione, altrimenti non mostra nulla *!/*/}
                                {/*    <label>{langSet.risoltoIl}</label>*/}
                                {/*    <p>{props.notifica.timestamp}</p>*/}
                                {/*</td>*/}
                                {/*<td colSpan={2} style={{*/}
                                {/*    padding: ".5em",*/}
                                {/*    display: props.notifica.confermata ? "" : "none"*/}
                                {/*}}> /!* Se la notifica è confermata mostra l'operatore che l'ha risolta, altrimenti non mostra nulla *!/*/}
                                {/*    <label>{langSet.operatore}</label>*/}
                                {/*    <p>{props.notifica.nomeutente}</p>*/}
                                {/*</td>*/}
                                <td colSpan={4} style={{padding: ".5em"}}>
                                    <label>{langSet.creatoIl}</label>
                                    <p>{props.notifica.creato}</p>
                                </td>

                                <td colSpan={4} style={{
                                    padding: ".5em",
                                    // display: props.notifica.confermata ? "" : "none"
                                }}> {/* Se la notifica è confermata mostra la data di risoluzione, altrimenti non mostra nulla */}
                                    <label>{langSet.risoltoIl}</label>
                                    <p>{props.notifica.confermata ? props.notifica.timestamp  : 'in attesa'}</p>
                                </td>

                                <td colSpan={4} style={{
                                    padding: ".5em",
                                    // display: props.notifica.confermata ? "" : "none"
                                }}> {/* Se la notifica è confermata mostra l'operatore che l'ha risolta, altrimenti non mostra nulla */}
                                    <label>{langSet.operatore}</label>
                                    <p>{props.notifica.nomeutente ? props.notifica.nomeutente : 'in attesa'}</p>
                                </td>

                            </tr>

                            {/*{props.notifica.id_sensore.includes("fall") ?*/}
                            {/*    <tr>*/}
                            {/*        <td colSpan={6}>*/}
                            {/*            Vera*/}
                            {/*        </td>*/}
                            {/*        <td colSpan={6}>*/}
                            {/*            Falsa*/}
                            {/*        </td>*/}
                            {/*    </tr>*/}
                            {/*    : <> </>*/}
                            {/*}*/}

                            {/*<tr>*/}
                            {/*    <td colSpan={6} style={{ padding: ".5em" }}>*/}
                            {/*        <label>{langSet.dispositivo}</label>*/}
                            {/*        <p>{props.notifica.id_sensore}</p>*/}
                            {/*        */}
                            {/*    </td>*/}
                            {/*</tr>*/}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="popupNavigator">
                    <img src={arrowPurple} alt="arrowPurple" style={{transform: "rotate(90deg)"}}
                         onClick={() => scroll("left")}/>
                    <p>&emsp;-&emsp;</p>
                    <img src={arrowPurple} alt="arrowPurple" style={{transform: "rotate(-90deg)"}}
                         onClick={() => scroll("right")}/>
                </div>
                <div className="popupButtons">
                    {/* Se la notifica non è confermata mostra il selettore, altrimenti non mostra nulla */}
                    {/* <div className="selettore selettoreBlue selettoreFlex"
                        //onClick={() => confermaNotifica()}
                         onClick={confermaNotifica}
                         style={{display: !props.notifica.confermata 
                                          ? "" 
                                          : props.notifica.confermata && props.notifica.note === undefined && props.notifica.nomeutente === accountData.email 
                                            ? "" 
                                            : "none" }}>
                        <p>{langSet.salvaERisolvi}</p>
                        <img className="icon-popup" src={confirmW} alt="confirm" />
                    </div> */}
                    {/* <div className="selettore selettoreViolet selettoreFlex" onClick={() => { resetData(); props.closePopup(); }} style={{ display: !props.notifica.confermata ? "" : props.notifica.confermata && props.notifica.note === undefined && props.notifica.nomeutente === accountData.email ? "" : "none" }}> 
                        <p>{langSet.annulla}</p>
                        <img className="icon-popup" src={close} alt="close" />
                    </div> */}
                </div>
            </div>
        </> 
    );
}

export default SchedaNotifica;